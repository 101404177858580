/* for color stuff*/
:root{
    --dark-blue: #1a535c;
    --light-blue:#4ecdc4;
    --white-blue:#f7fff7;
    --red:#ff6b6b;
    --yellow:#ffe66d;
    --black:#2b2d42;
}

*{
    
}

.darker-background{
    background:linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        );
}

nav a{
    padding:10px 5px 10px 5px;
    border-radius:5px;
    color:var(--red);
    
}

nav a:hover{
    background:linear-gradient(
          rgba(0, 0, 0, 0.3), 
          rgba(0, 0, 0, 0.3)
        );
}


.about:hover{
    background:linear-gradient(
        rgba(0, 0, 0, 0.3), 
        rgba(0, 0, 0, 0.3)
      );
}

.white-font{
    color:var(--white-blue);
}

.black-font{
    color:var(--black)
}

.white-background{
    background-color:var(--white-blue);
}

.red-background{
    background-color:var(--red);
}

.transp-background{
    background-color:none;
}

.red-font{
    color:var(--red);
}

.dropbtn {
    background-color:transparent;
    color: var(--red);
    font-size:20px;
    border: none;
    cursor: pointer;
  }

.dropdown-content a {
    color: var(--red);
}

.projects img:hover{
    background:linear-gradient(
        rgb(255, 255, 255, 0.3), 
        rgba(255, 255, 255, 0.3)
      );
      padding:10px;
      border-radius:10px;
}

.social-link{
    color:white;
    width:20px;
    margin:0;
}

header{
    background-image:url("../media/banners/navheader.png");
    background-size:cover;
}

.button{
    background-color:var(--red);
    padding:10px;
    text-align:center;
}

.button:hover{
    background:linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      );
}