*
{
    font-family:rubik;
}
ul{
    list-style: none;
}

h1{
    text-align:center;
    font-family:rubik;
    font-weight:100;
    font-size:44px;
    padding:20px;
}

h2{
    font-family:rubik;
    font-weight:100;
    padding:15px 0 0 0;
}

a {
    text-decoration: none; /* no underline */
    font-size:20px;
    font-weight:400;
    font-family:montserrat;
}

.bold{
    font-weight:400;
}

.thick-header{
    font-weight:400;
    font-size:50px;
    text-align:center;
}

.thin-header{
    font-size:35px;
}

.slightly-transparent{
    opacity:0.9;
}

.body1 p{
    font-family:rubik;
    text-align:center;
    padding:0 5% 0 5%;
}

.cursive-h2 {
    font-family: "Regular";
    clear: both;
   
    overflow: hidden;
    white-space: nowrap;
    transform: translateY(-20%);
    font-size:80px;
}
.small-p{
    font-size:20px;
}

.medium-p{
    font-size:23px;
}

.big-p{
    font-size:35px;
}

.small-line{
    height:2px;
    width:200px;
    left:50%;
    position:relative;
    transform:translate(-50%,0);
    background-color:#2b2d42;
    margin:0;
}

.team .row p{
    font-size:20px;
    font-weight:600;
}

.team .row .subtitle{
    font-weight:400;
    font-size:16px;
}

.gallery .thick-header{
    margin-bottom:10px;
}

.quotes h2{
    font-size:40px;
}

.span-link{
    display:inline !important; 
    font-weight: 500;
    padding:0;
    margin:0;
    color:#ff6b6b;
    font-size:inherit;
    font-style: underline;
}

/* 
DROPDOWN CODE */
/* Style The Dropdown Button */

  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    z-index:10;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    padding: 10px 12px;
    text-decoration: none;
    border-radius:0;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
    z-index:10
  }
  
  .dropbtn{
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    font-family: montserrat;
  }


.donate-button{
    color:#ff6b6b;
    background-color:#2b2d42;
    width:400px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 1px #2b2d42;
    margin:10px 20px 100px 20px;

}

.donate{
    display:flex;
    flex-direction:column;
    align-items: center;
    line-height:1.5;
    padding-bottom:30px;
}

.donation-list{
    display:flex;
    flex-direction:row;
}

/* .modalInner{

} */

.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.2);
    z-index:0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalContainer {
    position:center;
    width: 900px;
    height: 900px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    overflow-y: auto;
  }
.modalImgContainer{
    top:    0%;
    /* position: left;
    width: 200px;
    height: auto; */
    flex: 1;
}
.modalImg{
    height: auto;
    width: 400px;
    object-fit: scale-down;
    margin-left: -75px;
    padding-right: 30px;
}

.modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
    padding-left: 20px;
    line-height: 1.6;
    overflow-y: auto;
    
  }
.modalContainer button {
    top: 7.5%;
    right: 30%;
    position: absolute;
    z-index: 1;
    background-color: black;
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

/* .darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal {
    width: 250px;
    height: 170px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  .modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  
  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }
  
  .modalActions {
    position: absolute;
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  } */