/* for image stuff*/

.header_logo{
    width:20%;
    background-color:transparent;
    padding:10px 0px 0px 20px;
}

.parallax{
    z-index:-1;
    position:absolute;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background1{
    height:90%;
    width:100%;
    background-image:url(../media/banners/header_background.jpg);
}

.background2{
    position:fixed;
    z-index:-2;
    bottom:0;
    height:100vh;
    width:100%;
    background-image:url(../media/banners/background2.png);
}

.background3{
    background-image:url(../media/banners/background3.png);
    height:110vh;
    width:100%;
}

.background4{
    position:fixed;
    z-index:-2;
    bottom:0;
    background-image:url(../media/banners/background4.jpg);
    height:100vh;
    width:100%;
}

.background5{
    position:fixed;
    z-index:-1;
    background-image:url(../media/banners/background5.jpg);
    height:100vh;
    width:100%;
}

.background6{
    background-image:url(../media/banners/background6.png);
    z-index:-1;
    height:100%;
    width:100%;
}

.background7{
    position:fixed;
    z-index:-2;
    bottom:0;
    background-image:url(../media/banners/background7.jpg);
    height:100vh;
    width:100%;
}

.background8{
    background-image:url(../media/banners/background8.jpg);
     min-height: 100%;
    min-width: 1024px;
        
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
        
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}


.header-background{
    position:absolute;
    left:50%;
    
    transform:translate(-50%,0);
    width:100%;
    top:-250px;
    max-width:100%;
    min-width:1150px;
    z-index:-1;
}

.social-icon{
    width:50px;
}

.body1 img{
    width:100%;
    margin:30px 0;
}

.body2 .fingerprint{
    width:100%;
    max-width:260px;
    min-width:150px;
}

.body2 img{
    width:100%;
}

.body3 img{
    position:relative;
    width:40%;
    min-width:100px;
}

.how{
    min-width:200px;
}

.body3 .team img{
    width:unset;
    position:relative;
    height:30vh;
    padding:5px;
}

.projects img{
    padding:10px;
    width:60%;
}

.gallery .body1 img{
    width:70%;
}

.donate-btn{
    width:300px;
}